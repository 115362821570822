import React from 'react'

const Loader = () => {
  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div className='GGCAdminloader'>
        </div>
      </div>
    </>
    // <div className="loader"><CircularProgress /></div>
  )
}

export default Loader