import React from 'react';
import { makeStyles, Paper, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    },
    paper: {
        padding: theme.spacing(4),
        textAlign: 'center',
    },
}));

const ComingSoon = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Typography variant="h4" gutterBottom>
                    Coming Soon
                </Typography>
                <Typography variant="body1">
                    We're working on something awesome! Stay tuned for updates.
                </Typography>
            </Paper>
        </div>
    );
};

export default ComingSoon;
