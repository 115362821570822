const initialState = {
  loading: false,
  createLoading: false,
  deleteLoading: false,
  updateLoading: false,
  eventData: {},
};

function EventReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_EVENT_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    case "CREATE_EVENT_LOADING":
      return {
        ...state,
        createLoading: action.payload,
      };
    case "CREATE_EVENT_SUCCESS":
      return {
        ...state,
      };
    case "UPDATE_EVENT_LOADING":
      return {
        ...state,
        updateLoading: action.payload,
      };
    case "UPDATE_EVENT_SUCCESS":
      return {
        ...state,
      };
    case "GET_EVENT_SUCCESS":
      return {
        ...state,
        eventData: action.payload,
      };
    case "DELETE_EVENT_LOADING":
      return {
        ...state,
        deleteLoading: action.payload,
      };
    case "DELETE_EVENT_SUCCESS":
      return {
        ...state,
      };

    default:
      return state;
  }
}

export default EventReducer;
