
const initialState = {
    loading: false,
    createLoading: false,
    deleteLoading: false,
    updateLoading: false,
    postalCodeData: null,
    allPostalData: [],
}

function PostalCodeReducer(state = initialState, action) {
    switch (action.type) {
        case "POSTAL_LOADING":
            return {
                ...state,
                loading: action.payload
            }
        case "CREATE_POSTAL_LOADING":
            return {
                ...state,
                createLoading: action.payload
            }
        case "DELETE_POSTAL_LOADING":
            return {
                ...state,
                deleteLoading: action.payload
            }
        case "UPDATE_POSTAL_LOADING":
            return {
                ...state,
                updateLoading: action.payload
            }
        case "DELETE_POSTAL_SUCCESS":
            return {
                ...state,
            }
        case "POSTAL_SUCCESS":
            return {
                ...state,
                postalCodeData: action.payload,
            }
        case "GET_ALL_POSTAL_SUCCESS":
            return {
                allPostalData: action.payload,
            }
        case "UPDATE_POSTAL_SUCCESS":
            return {
                ...state,
            }
        case "CREATE_POSTAL_SUCCESS":
            return {
                ...state,
            }
        default:
            return state;
    }
}

export default PostalCodeReducer;