import { enqueueSnackbar } from "notistack";
import { LIVE_URL } from "../../ApiUrlConstant";

export const base_Url = LIVE_URL;

export const snackBar = (message, status) =>
  enqueueSnackbar(message || "Internal Server Error", {
    variant: status ? "success" : "error",
    autoHideDuration: 2000,
    isOpen: true,
  });
