const initialState = {
    getWalletLoading: false,
    createLoading: false,
    uploadLoading: false,
    deleteLoading: false,
    wallet: [],
}

export default function WalletReducer(state = initialState, action) {
    switch (action.type) {
        case "GET_WALLET_LOADING":
            return {
                ...state,
                getWalletLoading: action.payload            }
        case "GET_WALLET_SUCCESS":
            return {
                ...state,
                getWalletLoading: false,
                wallet: action.payload
            }
        case "GET_WALLET_FAIL":
            return {
                ...state,
                getWalletLoading: false,
                wallet: action.payload
            }
        default:
            return state;
    }
}