import React, { Component } from "react";
import { Context } from "./Context";

class Provider extends Component {
  state = { map: null };

  setMap = map => {
    this.setState({ map });
  };

  render() {
    return (
      <Context.Provider value={{ map: this.state.map, setMap: this.setMap }} >
        {this.props.children}
      </Context.Provider>
    );
  }
}

export default Provider;
