
const initialState = {
    loading: false,
    updateLoading: false,
    deleteLoading: false,
    createLoading: false,
    subscriptionData: {},
    updateSubscribeData: {},
}

function SubscriptionReducer(state = initialState, action) {
    switch (action.type) {
        case "LOADING_SUBSCRIBE":
            return {
                ...state,
                loading: action.payload
            }
        case "SUBSCRIBE_SUCCESS":
            return {
                ...state,
                subscriptionData: action.payload,
            }
        case "UPDATE_SUBSCRIBE_SUCCESS":
            return {
                ...state,
                updateSubscribeData: action.payload,
            }
        case "UPDATE_LOADING_SUBSCRIBE":
            return {
                ...state,
                updateLoading: action.payload,
            }
        case "CREATE_LOADING_SUBSCRIBE":
            return {
                ...state,
                createLoading: action.payload,
            }
        case "CREATE_SUBSCRIBE_SUCCESS":
            return {
                ...state,
            }
        case "DELETE_LOADING_SUBSCRIBE":
            return {
                ...state,
                deleteLoading: action.payload,
            }
        case "DELETE_SUBSCRIBE_SUCCESS":
            return {
                ...state,
            }
        default:
            return state;
    }
}

export default SubscriptionReducer;