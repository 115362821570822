import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { Form, useForm } from "../../common/useForms";
import { useNavigate } from "react-router-dom";
import ButtonCom from "../../common/Button";
import Logo from "../../assets/images/logo-home.svg";
import { themeSettings } from "../../theme";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  main: {
    background:
      "linear-gradient(317deg, rgba(254,118,120,1) 9%, rgba(21,62,128,1) 100%)",
    height: "100%",

    [theme.breakpoints.down("sm")]: {
      height: "100vh",
      padding: "10px",
    },

    [theme.breakpoints.up("md")]: {
      height: "100vh",
    },

    display: "flex",
    alignItems: "center",
  },
  subMain: {
    width: "450px",
    margin: "0 auto",
    backgroundColor: "#fff",
    borderRadius: "20px",
  },
  
  logo: {
    marginBottom: "20px",
    width: "60% !important",
    display: "block",
    margin: "0 auto",
  },
  mainCard: {
    boxShadow: "none !important",
    backgroundColor: "#fff !important",
    color: "#000 !important",
    display: "block",
    textAlign: "center",
    minWidth: 275,
  },
  boxCard: {
    [theme.breakpoints.up("xs")]: {
      padding: "20px",
    },

    [theme.breakpoints.up("md")]: {
      padding: "40px",
    },
  },

  send: {
    ":hover": { bgcolor: "#153E80", color: "#FFFFFF" },
    marginTop: "20px !important",
    textTransform: "none !important",
    fontSize: "16px !important",
    color: "#FFFFFF !important",
    width: "100%",
    backgroundColor: "#153E80 !important",
    border: "2px solid #153E80 !important",
    borderRadius: "15px !important",
    padding: "10px 40px !important",
    "&.Mui-disabled": {
      backgroundColor: "#153E80 !important",
      color: "#fff !important",
    },
    marginLeft: "3px !important",
  },
}));

const initialValues = { email: "", password: "" };

const Login = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const classes = useStyles();

  const typography = themeSettings(theme.palette.mode);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("email" in fieldValues) {
      if (fieldValues.email !== "" && fieldValues.email !== undefined) {
        const emailRegex =
          /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.(com|org|gov|edu|net|mil|in)$/;
        const isEmailValid = emailRegex.test(fieldValues.email);
        if (!isEmailValid) {
          temp.email = "Invalid email address. Please enter a valid email.";
        } else {
          temp.email = "";
        }
      } else {
        temp.email = "This field is required.";
      }
    }
    if ("password" in fieldValues) {
      if (fieldValues.password !== "" && fieldValues.password !== undefined) {
        if (fieldValues.password.length < 8) {
          temp.password = "Password  must be 8 charecter";
        } else {
          if (!/[0-9]/.test(fieldValues.password)) {
            temp.password = "Password  must be 1 Number";
          } else {
            if (!/[a-z]/.test(fieldValues.password)) {
              temp.password = "Password  must be 1 Lowercase letter";
            } else {
              if (!/[A-Z]/.test(fieldValues.password)) {
                temp.password = "Password  must be 1 Uppercase letter";
              } else {
                // setPass(fieldValues.password);
                temp.password = "";
              }
            }
          }
        }
      } else {
        temp.password = "This field is required.";
      }
    }
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const body = {
        email: values.email,
        password: values.password,
      };
    }
  };

  return (
    <Box className={classes.main}>
      <Box className={classes.subMain}>
        <Grid
          container
          item
          columns={{ xs: 4, sm: 12, md: 12 }}
          spacing={1}
          sx={{
            alignItems: "center",
          }}
        >
          <Grid item xs={12} md={12}>
            <Box className={classes.boxCard}>
              <CardMedia
                className={classes.logo}
                component="img"
                image={Logo}
                alt="Paella dish"
              />

              <Form onSubmit={handleSubmit}>
                <Box>
                  <Card className={classes.mainCard}>
                    <CardContent>
                      <Typography fontSize={typography.typography.h5.fontSize}>
                        Welcome
                      </Typography>
                      <Typography fontSize={typography.typography.h5.fontSize}>
                        to
                      </Typography>
                      <Typography fontSize={typography.typography.h5.fontSize}>
                        Global Garner Community
                      </Typography>
                      <br />
                      <Typography fontSize={typography.typography.h5.fontSize}>
                        Building a stronger tomorrow
                      </Typography>
                    </CardContent>
                  </Card>
                </Box>

                <ButtonCom
                  onClick={() => navigate("/admin/login")}
                  className={classes.send}
                  type="submit"
                  text={"Continue with Login"}
                ></ButtonCom>
              </Form>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Login;
