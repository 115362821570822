import axios from "../ApiConfig";
import { ApiURL, snackBar } from "../common";

export const GetSubscription = (page, search = '') => async (dispatch) => {
  try {
    dispatch({
      type: "LOADING_SUBSCRIBE",
      payload: true,
    });
    const res = await axios.get(`${ApiURL}membership/get-all-memberships?limit=10&page=${page}&search=${search}`);

    if (res.data.status === true) {
      dispatch({
        type: "LOADING_SUBSCRIBE",
        payload: false,
      });
      dispatch({
        type: "SUBSCRIBE_SUCCESS",
        payload: res.data.data,
      });
      return res;
    }
  } catch (err) {
    console.error(err);
    dispatch({
      type: "LOADING_SUBSCRIBE",
      payload: false,
    });
  }
};

export const UpdateSubscription = (id, data, handleClose) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_LOADING_SUBSCRIBE",
      payload: true,
    });
    const res = await axios.put(`${ApiURL}membership/update/${id}`, data);

    if (res.data.status === true) {
      dispatch({
        type: "UPDATE_SUBSCRIBE_SUCCESS",
        payload: res.data.data,
      });
      snackBar(res?.data?.message, res?.data?.status)
      handleClose()
      return res;
    }
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status)
    console.error(err);
  } finally {
    dispatch({
      type: "UPDATE_LOADING_SUBSCRIBE",
      payload: false,
    });
  }
};

export const CreateSubscription = (data, handleClose) => async (dispatch) => {
  try {
    dispatch({
      type: "CREATE_LOADING_SUBSCRIBE",
      payload: true,
    });
    const res = await axios.post(`${ApiURL}membership/create-membership`, data);

    if (res.data.status === true) {
      dispatch({
        type: "CREATE_SUBSCRIBE_SUCCESS",
        payload: res.data.data,
      });
      snackBar(res?.data?.message, res?.data?.status)
      handleClose()
      return res;
    }
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status)
    console.error(err);
  } finally {
    dispatch({
      type: "CREATE_LOADING_SUBSCRIBE",
      payload: false,
    });
  }
};

export const DeleteSubscription = (id, handleCloseDelete) => async (dispatch) => {
  try {
    dispatch({
      type: "DELETE_LOADING_SUBSCRIBE",
      payload: true,
    });
    const res = await axios.delete(`${ApiURL}membership/delete/${id}`);

    if (res.data.status === true) {
      dispatch({
        type: "DELETE_SUBSCRIBE_SUCCESS",
        payload: res.data.data,
      });
      snackBar(res?.data?.message, res?.data?.status)
      handleCloseDelete()
      return res;
    }
  } catch (err) {
    snackBar(err?.response?.data?.message, err?.response?.data?.status)
    console.error(err);
  } finally {
    dispatch({
      type: "DELETE_LOADING_SUBSCRIBE",
      payload: false,
    });
  }
};
