import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import ReviewCard from "../reviewCard/ReviewCard";
import { GetClientReview } from "../../redux/clientReviewAction";

const ClientReview = ({title,cardsPerScreen}) => {

  const [allGetClient, setAllGetClient] = useState([]);

  const getClient = async () => {
    const res = await GetClientReview();
    setAllGetClient(res?.data);
  };

  useEffect(() => {
    getClient();
  }, []);
  const settings = {
    dots: false,
    infinite: false,
    arrows: false,
    className: "directory-slide-show",
    speed: 300,
    slidesToShow: cardsPerScreen || 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
    {allGetClient?.reviewData?.length > 0 ?(
      <div className="pt-5 bg-gray-02 pb-5">
      <div className="container">
        <h3 className="mb-0 text-center text-capitalize">
          {title || "clients say about GGC?"}
        </h3>
        <div className="container">
          <div className="row">
            <div className="col col-md-12">
              <Slider {...settings}>
                {allGetClient && allGetClient?.reviewData?.map((item) => (
                  <ReviewCard item={item}/>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
    ):null}
    </>

  
  );
};

export default ClientReview;
