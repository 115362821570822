import React from "react";
import { Typography, Button, Container, CssBaseline, Grid } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";

const PendingRequest = () => {
  return (
    <Container
      component="main"
      maxWidth="sm"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "3rem",
      }}
    >
      <CssBaseline />
      <Grid container spacing={2} direction="column" alignItems="center">
        <Grid item>
          <LockIcon sx={{ fontSize: 100, color: "#1C3E80" }} />
        </Grid>
        <Grid item>
          <Typography variant="h4" align="center" gutterBottom>
            Pending Approval
          </Typography>
          <Typography variant="body1" align="center" color="textSecondary">
            Thank you for your request! Your access to the page is pending
            approval. We'll notify you as soon as it's approved so you can
            access the content. We appreciate your patience!
          </Typography>
        </Grid>
        {/* You can add a button or any additional elements here */}
      </Grid>
    </Container>
  );
};

export default PendingRequest;
