import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import InternalServerError from "../../../pages/InternalServerError.js";
import { DisplayHTMLString } from "../../../common/HtmlToString.js";
import Loader from "../../components/loader/Loader";

import { api } from "../../config/api";
import { ApiURL } from "../../../redux/common";

const PrivacyPolicy = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState("");
  const [error, setError] = useState(false);

  const getPrivacyPolicyData = async () => {
    try {
      const res = await api.get(`${ApiURL}privacy-policy`);
      if (res?.status) {
        setData(res?.data?.policy);
      } else {
        setData("");
      }
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPrivacyPolicyData();
  }, []);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <InternalServerError />;
  }

  return (
    <>
      <div>
        <div id="page-title" className="page-title pt-12 text-center">
          <div className="container">
            <div className="h-100 ">
              <h1
                className="mb-0 fadeInDown animated"
                data-animate="fadeInDown"
              >
                Privacy Policy
              </h1>
              <ul
                className="breadcrumb justify-content-center fadeInUp animated"
                data-animate="fadeInUp"
              >
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <span>Privacy Policy</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div id="wrapper-content" className="wrapper-content pt-10 pb-0">
          <div className="container">{DisplayHTMLString(data)}</div>
          <div id="cta" className="bg-primary py-7">
            <div className="container">
              <div className="row align-items-center">
                <div className="text-white col-xl-9">
                  <h2 className="mb-1 text-white">
                    Have a question? Contact us now !
                  </h2>
                  <p className="mb-0 font-size-md">
                    We're here to help. Check out our FAQs, send us an email or
                    call us at 1 (800) 555-5555
                  </p>
                </div>
                <div className="col-xl-3 mt-4 mt-xl-0 text-left text-xl-right">
                  <Link
                    className="btn btn-white btn-icon-right font-size-h5 px-6 py-3 lh-1 text-primary"
                    to="/contact-us"
                  >
                    Contact US
                    <i className="fal fa-chevron-right" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
