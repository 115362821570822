import React from "react";
import Faqs from "../../components/Faqs/Faqs";
import { Link } from "react-router-dom";

const FaqPage = () => {
  return (
    <>
      <div id="site-wrapper" className="site-wrapper page-faqs py-3">
        <div id="page-title" class="page-title text-center pt-11">
          <div class="container">
            <div class="h-100">
              <h1
                class="mb-0 letter-spacing-50 fadeInDown animated"
                data-animate="fadeInDown"
              >
                Frequently Asked Questions
              </h1>
              <ul
                class="breadcrumb justify-content-center fadeInUp animated"
                data-animate="fadeInUp"
              >
                <li class="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li class="breadcrumb-item">
                  <span>FAQs</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div id="wrapper-content" className="wrapper-content py-11">
          <div className="container">
            <div id="section-accordion" className="faqs mb-11">
              <div className="container">
                <div className="col-md-12">
                  <Faqs />
                </div>
              </div>
            </div>
            <div class="text-center pb-5">
              <div class="mb-6">
                <h4 class="mb-1">
                  Join Us Today and Get More Profit From Your Business
                </h4>
                <p class="mb-0 font-size-md">
                  The easiest way to get more interest in your place
                </p>
              </div>
              <Link
                to="/be-partner"
                class="btn btn-primary font-size-md font-weight-bold px-7 btn-lg"
              >
                Get Start Now!
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FaqPage;
