import React from "react";
import { styled } from "@mui/material/styles";

// Styled components
const StyledCard = styled("div")({
  borderRadius: "20px !important",
  border: "1px solid #F1F0F0 !important",
  background: "#FFF",
  transition: "all 0.3s ease",
  boxShadow: "none !important",
  "&:hover": {
    borderRadius: "30px",
    border: "1px solid #F1F0F0",
    background: "#FFF",
    boxShadow: "0px 20px 20px 0px rgba(0, 0, 0, 0.08) !important",
  },
});

const StyledImageContainer = styled("div")({
  // borderRadius: "50%",
  // heigth: "auto !important",
  // border: "2px solid #F1F0F0",
  // background: "#FFF",
});

const StyledImage = styled("img")({
  // width: "300px",
  // height: "250px",
});

const StyledTitle = styled("div")({
  color: "#000",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
});

const StyledDescription = styled("p")({
  color: "#8B8585",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
});

const BeCard = ({ cardImage, title, description }) => {
  return (
    <div
      className="col-md-4 col-sm-12 p-1 px-2"
      style={{ display: "flex", alignItems: "stretch" }}
    >
      <StyledCard className="card border-0 rounded-0 px-6 py-5 box-shadow-hover">
        <StyledImageContainer className="image pb-3 mx-auto">
          <StyledImage src={cardImage} alt="Image box" />
        </StyledImageContainer>
        <div className="card-body p-0 mt-1">
          <StyledTitle className="card-title text-center mb-5 text-dark">
            {title}
          </StyledTitle>
          <StyledDescription className="card-text text-justify text-gray">
            {description}
          </StyledDescription>
        </div>
      </StyledCard>
    </div>
  );
};

export default BeCard;
