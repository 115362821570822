
const initialState = {
    loading: false,
    createLoading: false,
    updateLoading: false,
    deleteLoading: false,
    banner: [],
    bannerType: [],
}

function BannerReducer(state = initialState, action) {
    switch (action.type) {
        case "LOADING":
            return {
                ...state,
                loading: action.payload
            }

        case "BANNER_SUCCESS":
            return {
                ...state,
                banner: action.payload,
                loading: false,
            }

        case "BANNER_TYPE_SUCCESS":
            return {
                ...state,
                bannerType: action.payload,
                loading: false,
            }

        case "CREATE_LOADING_BANNER":
            return {
                ...state,
                createLoading: action.payload
            }

        case "CREATE_BANNER_SUCCESS":
            return {
                ...state,
            }

        case "UPDATE_LOADING_BANNER":
            return {
                ...state,
                updateLoading: action.payload
            }
        case "UPDATE_BANNER_SUCCESS":
            return {
                ...state,
            }

        case "DELETE_BANNER_LOADING":
            return {
                ...state,
                deleteLoading: action.payload
            }

        case "DELETE_BANNER_SUCCESS":
            return {
                ...state,
            }


        default:
            return state;
    }
}

export default BannerReducer;