
const initialState = {
    loading: false,
    createLoading: false,
    deleteLoading: false,
    updateLoading: false,
    membershipData: {},
}

function MembershipReducer(state = initialState, action) {
    switch (action.type) {
        case "CREATE_MEMBERSHIP_LOADING":
            return {
                ...state,
                createLoading: action.payload
            }
        case "CREATE_MEMBERSHIP_SUCCESS":
            return {
                ...state,
            }
        case "GET_MEMBERSHIP_LOADING":
            return {
                ...state,
                loading: action.payload
            }
        case "GET_MEMBERSHIP_SUCCESS":
            return {
                ...state,
                membershipData: action.payload
            }
        case "DELETE_MEMBERSHIP_LOADING":
            return {
                ...state,
                deleteLoading: action.payload
            }
        case "DELETE_MEMBERSHIP_SUCCESS":
            return {
                ...state,
            }
        case "UPDATE_MEMBERSHIP_LOADING":
            return {
                ...state,
                updateLoading: action.payload
            }
        case "UPDATE_MEMBERSHIP_SUCCESS":
            return {
                ...state,
            }

        default:
            return state;
    }
}

export default MembershipReducer;