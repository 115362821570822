const initialState = {
  loading: false,
  notification: [],
  createLoading: false,
  updateLoading: false,
  deleteLoading: false,
  sendeLoading:false,
};

function NotificationReducer(state = initialState, action) {
  switch (action.type) {
    case "NOTIFICATION_GET_LOADING":
      return {
        ...state,
        loading: action.payload,
      };

    case "NOTIFICATION_GET_SUCCESS":
      return {
        ...state,
        notification: action.payload,
        loading: false,
      };

    case "CREATE_LOADING_NOTIFICATION":
      return {
        ...state,
        createLoading: action.payload
      }

    case "CREATE_NOTIFICATION_SUCCESS":
      return {
        ...state,
      }

    case "SEND_LOADING_NOTIFICATION":
      return {
        ...state,
        sendeLoading: action.payload
      }

    case "SEND_NOTIFICATION_SUCCESS":
      return {
        ...state,
      }

    case "UPDATE_LOADING_NOTIFICATION":
      return {
        ...state,
        updateLoading: action.payload
      }
    case "UPDATE_NOTIFICATION_SUCCESS":
      return {
        ...state,
      }

    case "DELETE_NOTIFICATION_LOADING":
      return {
        ...state,
        deleteLoading: action.payload
      }

    case "DELETE_NOTIFICATION_SUCCESS":
      return {
        ...state,
      }

    default:
      return state;
  }
}

export default NotificationReducer;
