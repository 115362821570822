import React, { useEffect, useState } from "react";
import bannerBg from "../../assets/image/banner/about-banner.jpg";
import Slider from "react-slick";
import axios from "axios";
import { ApiURL, snackBar } from "../../../redux/common";
import Loader from "../../components/loader/Loader";
import { useNavigate } from "react-router-dom";


var settings = {
  dots: false,
  infinite: true,
  arrows: false,
  // className: "hero-banner-arrow",
  autoplay: true,
  autoplaySpeed: 2000,
  speed: 300,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1023,
      settings: {
        arrows: false,
        slidesToShow: 1,
      },
    },
  ],
};
const About = () => {
  const [aboutDetail, setAboutDetail] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    getAboutUsDetail()
  }, [])
  const getAboutUsDetail = async () => {
    setLoading(true)
    try {
      await axios.get(`${ApiURL}about-us`).then((res) => {
        if (res.data.status) {
          setLoading(false)
          setAboutDetail(res.data.data)
        } else {
          setLoading(false)
          snackBar(res.data.message, res.data.status)
        }
      })
    } catch (error) {
      setLoading(false)
      snackBar(error, false)
    }
  }

  return (
    <div>
      <div id="site-wrapper" className="site-wrapper page-about">
        <div id="wrapper-content" className="wrapper-content pt-0 pb-0">
          <div
            className="banner"
            style={{
              backgroundImage: `url(${bannerBg})`,
            }}
          >
            <div className="container">
              <div
                className="banner-content text-center"
                style={{ paddingTop: "0%", paddingBottom: "20%" }}
              >
                <div className="heading d-flex justify-content-center align-item-center">
                  <h1 className="mb-0 text-white" style={{ paddingTop: "20%" }}>
                    About us
                  </h1>
                </div>
              </div>
            </div>
          </div>
          {loading ?
            <div className="mt-10 mb-10">
              <Loader />
            </div>
            :
            <>
              <div className="div-2 pt-0 pb-0">
                <div className="div-3">
                  <div className="div-4">
                    <div className="div-5 ">
                      <div className="column">
                        <div className="div-6">
                          <img loading="lazy" src={aboutDetail && aboutDetail?.aboutus_image1} className="img-2" />
                        </div>
                      </div>
                      <div className="column-2">
                        <span className="span">
                          <div className="div-7">
                            {aboutDetail && aboutDetail?.subtitle1}
                          </div>
                          <div className="div-8">
                            {aboutDetail && aboutDetail?.title1}
                          </div>
                          <div className="div-9">
                            {aboutDetail && aboutDetail?.description1}
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="div-11 pt-0 pb-0">
                <span className="span-2">
                  <div className="div-12">
                    {aboutDetail && aboutDetail?.subtitle2}
                  </div>
                  <div className="div-13">{aboutDetail && aboutDetail?.title2}</div>
                  <div className="div-14">
                    <div className="div-15">
                      <div className="column-3">
                        <span className="span-3">
                          <div className="div-16">{aboutDetail && aboutDetail?.subpoints1}</div>
                          <div className="div-17">
                            {aboutDetail && aboutDetail?.subpoints_description1}
                          </div>
                        </span>
                      </div>
                      <div className="column-4">
                        <span className="span-3">
                          <div className="div-18">{aboutDetail && aboutDetail?.subpoints2}</div>
                          <div className="div-19">
                            {aboutDetail && aboutDetail?.subpoints_description2}
                          </div>
                        </span>
                      </div>
                      <div className="column-5">
                        <span className="span-3">
                          <div className="div-20">{aboutDetail && aboutDetail?.subpoints3}</div>
                          <div className="div-21">
                            {aboutDetail && aboutDetail?.subpoints_description3}
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                </span>
              </div>
              <div className="div-23 pt-0 pb-0">
                <div className="div-24">
                  <div className="div-25">
                    <div className="div-26 wrap-reverse">
                      <div className="column-6">
                        <span className="span">
                          <div className="div-27">{aboutDetail && aboutDetail?.subtitle3}</div>
                          <div className="div-28">
                            {aboutDetail && aboutDetail?.title3}
                          </div>
                          <div className="div-29">
                            {aboutDetail && aboutDetail?.description3}
                          </div>
                        </span>
                      </div>
                      <div className="column-7">
                        <img loading="lazy" src={aboutDetail && aboutDetail?.aboutus_image3} className="img-4" />
                      </div>
                    </div>
                  </div>
                  <div className="div-30" />
                </div>
              </div>
              <div className="div-23">
                <div className="div-24">
                  <div className="div-25">
                    <div className="div-26 wrap">
                      <div className="column-7">
                        <img loading="lazy" src={aboutDetail && aboutDetail?.aboutus_image4} className="img-4" />
                      </div>
                      <div className="column-6">
                        <span className="span">
                          <div className="div-27">{aboutDetail && aboutDetail?.subtitle4}</div>
                          <div className="div-28">
                            {aboutDetail && aboutDetail?.title4}
                          </div>
                          <div className="div-29">
                            {aboutDetail && aboutDetail?.description4}
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="div-30" />
                </div>
              </div>
              <div className="div-40">
                <div className="div-41 row">
                  <span className="span-7 col-md-2">
                    <div className="div-42">Trusted By</div>
                    <div className="div-43">Clients</div>
                  </span>
                  <div className="col-md-9">
                    <Slider {...settings}>
                      {aboutDetail && aboutDetail?.trusted_images.map((data) => (
                        <div>
                          <div className="div-44">
                            <img src={data} className="img-6" />
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
              <div className="div-45 container">
                <div className="div-46">
                  <span className="span-8">
                    <div className="div-47">
                      Are you a local service professional?
                    </div>
                    <div className="div-48">
                      Learn how GGCOMMUNITY can help you double your business
                    </div>
                  </span>
                  <span onClick={()=>navigate("/contact-us")} className="span-9">Contact Now</span>
                </div>
              </div>
            </>
          }
        </div>
      </div>
    </div>
  );
};

export default About;
