
const initialState = {
    loading: false,
    createLoading: false,
    deleteLoading: false,
    updateLoading: false,
    stateData: {},
    allStateData: [],
}

function StateReducer(state = initialState, action) {
    switch (action.type) {
        case "STATE_LOADING":
            return {
                ...state,
                loading: action.payload
            }
        case "CREATE_STATE_LOADING":
            return {
                ...state,
                createLoading: action.payload
            }
        case "DELETE_STATE_LOADING":
            return {
                ...state,
                deleteLoading: action.payload
            }
        case "UPDATE_STATE_LOADING":
            return {
                ...state,
                updateLoading: action.payload
            }
        case "DELETE_STATE_SUCCESS":
            return {
                ...state,
            }
        case "STATES_SUCCESS":
            return {
                ...state,
                stateData: action.payload,
            }
        case "ALL_STATES_SUCCESS":
            return {
                ...state,
                allStateData: action.payload,
            }
        case "UPDATE_STATE_SUCCESS":
            return {
                ...state,
            }
        case "CREATE_STATE_SUCCESS":
            return {
                ...state,
            }


        case "STATE_SUCCESS":
            return {
                ...state,
                state: action.payload,
            }
        case "CITY_SUCCESS":
            return {
                ...state,
                city: action.payload,
            }

        default:
            return state;
    }
}

export default StateReducer;