import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box, IconButton } from "@material-ui/core";
import BookIcon from "@mui/icons-material/Book";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    padding: theme.spacing(4),
  },
  icon: {
    color: theme.palette.text.secondary,
  },
}));

const NoBlogContent = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box>
        <BookIcon className={classes.icon} style={{ fontSize: "60px !important" }} />
      </Box>
      <Typography variant="h5" color="textSecondary">
        No articles available
      </Typography>
      <Typography variant="body2" color="textSecondary">
        Check back later for more interesting content!
      </Typography>
    </div>
  );
};

export default NoBlogContent;
