import React from "react";
import ManageSubscriptionList from "../components/managesubscription/ManageSubscriptionList";

const ManageSubscription = () => {
  return (
    <div>
      <ManageSubscriptionList />
    </div>
  );
};

export default ManageSubscription;
