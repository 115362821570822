import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { GetUserProfile } from "../../redux/action/UserManageAction";

const AdminProtected = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("access_token");
  const userData = JSON.parse(localStorage.getItem("user_data"));
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(GetUserProfile(navigate));
  }, []);

  return <>{token ? <Outlet /> : <Navigate to="/" />}</>;
};

export default AdminProtected;
