import axios from "axios";
import { LIVE_URL } from "../../ApiUrlConstant";

let base_Url = LIVE_URL;

export const api = axios.create({
  baseURL: base_Url,
  headers: {
    "Content-Type": "application/json",
  },
});
api.interceptors.request.use(
  (req) => {
    let authRoute = req.url.includes("login") || req.url.includes("singup");
    if (!authRoute) {
      const token = localStorage.getItem("access_token");
      // const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTcxNTJkZWRhOWFhYjRjMTdlNTUwNjMiLCJlbWFpbCI6ImNtQGdtYWlsLmNvbSIsIm1vYmlsZV9udW1iZXIiOjc4ODk0NTU2MTIsImlhdCI6MTcwMjYxNzAzNCwiZXhwIjoxNzAyNzAzNDM0fQ.oJof8IeLkByTAA_qny2QzoWU0W0LzLARsth1dvdyy-w"
      if (token && token !== "undefined") {
        req.headers["Authorization"] = `Bearer ${token}`;
      }
    }
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (res) => {
    if (res.data.error) {
      return Promise.reject(res.data);
    } else {
      return Promise.resolve(res.data);
    }
  },
  (error) => {
    // localStorage.clear();
    // window.location.reload();
  }
);
