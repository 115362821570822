import React from "react";
import ManagePermissionList from "../components/managePermission/ManagePermissionList";

const ManagePermission = () => {
  return (
    <div>
      <ManagePermissionList />
    </div>
  );
};

export default ManagePermission;
