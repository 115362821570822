import React from "react";
import { Box, Typography, Divider } from "@mui/material";
import { styled } from "@mui/system";
import { Warning } from "@mui/icons-material";

const NoDataContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  color: theme.palette.text.secondary,
}));

const NoDataIcon = styled("div")(({ theme }) => ({
  fontSize: "48px",
  color: theme.palette.text.secondary,
}));

const NoDataMessage = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(2),
  textAlign: "center",
}));

const NoDataChart = () => (
  <NoDataContainer>
    <NoDataIcon>
      <Warning />
    </NoDataIcon>
    <NoDataMessage>
      <Typography variant="subtitle1">No data available</Typography>
    </NoDataMessage>
  </NoDataContainer>
);

export default NoDataChart;