import React from 'react'
import { Navigate, Outlet } from "react-router-dom";


const PublicRoute = () => {
  const token = localStorage.getItem('access_token')

  return (
    <>
      {(!token) ? <Outlet /> : <Navigate to="/admin/dashboard" />}
    </>
  )
}

export default PublicRoute