import React, { useEffect } from "react";
import Header from "../header/Header";
import { Outlet, useNavigate } from "react-router-dom";
import Footer from "../footer/Footer";
import AnimatedCallToAction from "../animatedCallToAction/AnimatedCallToAction";
import { useDispatch } from "react-redux";
import { GetUserProfile } from "../../../redux/action/UserManageAction";

const WebLayout = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("access_token");
  const navigate = useNavigate();
  useEffect(() => {
    if (token) {
      dispatch(GetUserProfile(navigate));
    }
  }, []);
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
      <AnimatedCallToAction />
    </>
  );
};

export default WebLayout;
