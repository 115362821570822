
import { enqueueSnackbar } from "notistack";
import axios from "../ApiConfig";
import { ApiURL, snackBar } from "../common";

const axiosApiCall = () => axios.create({
  headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
    },
  });


export const GetAllBanner = (page, rowsPerPage) => async (dispatch) => {
    try {
      dispatch({
        type: "LOADING",
        payload: true,
      });
  
      const res = await axios.get(`${ApiURL}banner/all-banner?limit=${rowsPerPage}&page=${page}`);
  
      if (res.data.status === true) {
        const responseData = res.data.data || res.data.data.length > 0 ? res.data.data : [];
        dispatch({
          type: "BANNER_SUCCESS",
          payload: responseData,
        });
      } else {
        dispatch({
          type: "BANNER_SUCCESS",
          payload: [],
        });
  
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: "LOADING",
        payload: false,
      });
    }
  };


  export const GetAllBannerType = (page, rowsPerPage) => async (dispatch) => {
    try {
      dispatch({
        type: "LOADING",
        payload: true,
      });
  
      const res = await axios.get(`${ApiURL}banner-type/get-all-type`);
  
      if (res.data.status === true) {
        const responseData = res.data.data || res.data.data.length > 0 ? res.data.data : [];
        dispatch({
          type: "BANNER_TYPE_SUCCESS",
          payload: responseData,
        });
      } else {
        dispatch({
          type: "BANNER_TYPE_SUCCESS",
          payload: [],
        });
  
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: "LOADING",
        payload: false,
      });
    }
  };


  export const CreateBanner = (data, handleClose) => async (dispatch) => {
    try {
      dispatch({
        type: "CREATE_LOADING_BANNER",
        payload: true,
      });
  
      const res = await axiosApiCall().post(`${ApiURL}banner/create-banner`, data);
  
      if (res.data.status === true) {
        dispatch({
          type: "CREATE_BANNER_SUCCESS",
          payload: res.data.data,
        });
        snackBar(res?.data?.message, res?.data?.status)
        handleClose()
        return res;
      }
    } catch (err) {
      snackBar(err?.response?.data?.message, err?.response?.data?.status)
      console.error(err);
    } finally {
      dispatch({
        type: "CREATE_LOADING_BANNER",
        payload: false,
      });
    }
  };
  
  
  export const UpdateBanner = (id, data, handleClose) => async (dispatch) => {
    try {
      dispatch({
        type: "UPDATE_LOADING_BANNER",
        payload: true,
      });
  
      const res = await axiosApiCall().put(`${ApiURL}banner/update-banner/${id}`, data);
  
      if (res.data.status === true) {
        dispatch({
          type: "UPDATE_BANNER_SUCCESS",
        });
        snackBar(res?.data?.message, res?.data?.status)
        handleClose()
        return res;
      }
      snackBar(res?.data?.message, res?.data?.status)
    } catch (err) {
      snackBar(err?.response?.data?.message, err?.response?.data?.status)
      console.error(err);
    } finally {
      dispatch({
        type: "UPDATE_LOADING_BANNER",
        payload: false,
      });
    }
  };
  
  export const DeleteBanner = (id, handleClose) => async (dispatch) => {
    try {
      dispatch({
        type: "DELETE_BANNER_LOADING",
        payload: true,
      });
      const res = await axios.delete(`${ApiURL}banner/delete-banner/${id}`);
  
      if (res.data.status === true) {
        dispatch({
          type: "DELETE_BANNER_LOADING",
          payload: false,
        });
        dispatch({
          type: "DELETE_BANNER_SUCCESS",
          payload: res.data.data,
        });
        snackBar(res?.data?.message, res?.data?.status)
        handleClose();
        return res;
      }
      snackBar(res?.data?.message, res?.data?.status)
    } catch (err) {
      snackBar(err?.response?.data?.message, err?.response?.data?.status)
      console.error(err);
      dispatch({
        type: "DELETE_BANNER_LOADING",
        payload: false,
      });
    }
  };