
const initialState = {
    loading: false,
    createLoading: false,
    deleteLoading: false,
    updateLoading: false,
    countryData: {},
    allCountryData: {},
    state: [],
    city: [],
}

function CountryReducer(state = initialState, action) {
    switch (action.type) {
        case "LOADING":
            return {
                ...state,
                loading: action.payload
            }
        case "CREATE_COUNTRY_LOADING":
            return {
                ...state,
                createLoading: action.payload
            }
        case "DELETE_COUNTRY_LOADING":
            return {
                ...state,
                deleteLoading: action.payload
            }
        case "UPDATE_COUNTRY_LOADING":
            return {
                ...state,
                updateLoading: action.payload
            }
        case "DELETE_COUNTRY_SUCCESS":
            return {
                ...state,
            }
        case "COUNTRIES_SUCCESS":
            return {
                ...state,
                countryData: action.payload,
            }
        case "ALL_COUNTRIES_SUCCESS":
            return {
                ...state,
                allCountryData: action.payload,
            }
        case "UPDATE_COUNTRY_SUCCESS":
            return {
                ...state,
            }
        case "CREATE_COUNTRY_SUCCESS":
            return {
                ...state,
            }


        case "STATE_SUCCESS":
            return {
                ...state,
                state: action.payload,
            }
        case "CITY_SUCCESS":
            return {
                ...state,
                city: action.payload,
            }


        default:
            return state;
    }
}

export default CountryReducer;